import React, { useEffect, useState } from "react";
import { Keyboard } from "./keyboard.js";
import 'react-piano/dist/styles.css';
import "./piano.css";
import { getQueryVariable, userTypes } from "./Utils.js";
import { socketOn } from "../socket/index.js";


let roomName = '';

let getRoom = getQueryVariable("roomName");
let _getRoom = getQueryVariable("room_name");
if (getRoom != '' && getRoom != undefined) {
  roomName = getRoom;
} else if (_getRoom != '' && _getRoom != undefined) {
  roomName = _getRoom;
}

// This code is using for web and mobile in case of we have already 
//managed the UI at web end to just adding the css for we only.

// just send the key : hasWeb in thr query string

let isWeb = false;
let hasWeb = getQueryVariable("hasWeb");

if (hasWeb != '' && hasWeb != undefined) {
   isWeb = true;
}


const Piano = ({ socket, Sessions, closeTool }) => {
  const { openByUser, SessionId } = Sessions;
  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    return function cleanup() {
      socket.off('played_note_return');
      socket.off('played_note_send');
    };
  }, [])


  return (
    <div className={"piano-container "  + (isWeb ? " web-piano-container" : '')} id="piano-container" >
      {/*  if disable piano to play for student */}
      {/* {isDisable && <div className="piano-over-lay"></div>} */}
      <Keyboard
        socket={socket}
        Sessions={Sessions}
        closeTool={closeTool}
      />
    </div>
  )
}

export default Piano;
